<template>
  <dropdown-menu :items="actions" :select="onActionSelect">
    <font-awesome-icon icon="ellipsis-h"
  /></dropdown-menu>
</template>

<script>
import get from "lodash/get";
import EventBus from "../../../../../../helpers/EventBus";
import { filtersMap } from "../../filtersMap";
import { PERMS } from "../../../../../../helpers/acl";
import {
  INCIDENT_ENTITY_TYPE,
  INCIDENT_TYPE,
  ORDER_ACTION,
} from "../../../../../../config/global";

export default {
  name: "ProductsTableActions",

  data() {
    const canEdit = this.canView(PERMS.ORDERS_EDIT);
    return {
      actions: [
        { id: ORDER_ACTION.FILTER, text: "Filtra" },
        {
          id: ORDER_ACTION.DONE,
          text: "Segna come consegnato",
          disabled: !canEdit,
        },
        {
          id: ORDER_ACTION.UNDONE,
          text: "Segna come NON consegnato",
          disabled: !canEdit,
        },
        {
          id: ORDER_ACTION.INCIDENT,
          text: "Apri segnalazione",
          disabled: !canEdit,
        },
        {
          id: ORDER_ACTION.CANCELLATION,
          text: "Annullato",
          disabled: !canEdit,
        },
      ],
    };
  },

  computed: {
    lang: function () {
      return this.$i18n.locale;
    },
  },

  methods: {
    getProductName() {
      return get(
        this.data,
        `ppm.lot.productproducer.translations.${this.lang}.name`
      );
    },

    getVariantName() {
      return get(this.data, `ppm.lot.translations.${this.lang}.name`);
    },

    onActionSelect: function (args) {
      const { id } = args.item;

      const odetails = [this.data.id];

      const onSuccess = (response) => {
        EventBus.$emit("orders:refresh");
      };

      if (id === ORDER_ACTION.FILTER) {
        const label = `${this.getProductName()} - ${this.getVariantName()}`;
        const param = { id: this.data.ppm.id, label: label };
        this.$store.commit("rounds/updateProductId", param);
        EventBus.$emit("orders:applyFilters");
      }

      if (id === ORDER_ACTION.DONE) {
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: filtersMap("orders.statusCode.next"),
            odetails,
          })
          .then(onSuccess);
      }

      if (id === ORDER_ACTION.UNDONE) {
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: filtersMap("orders.statusCode.prev"),
            odetails,
          })
          .then(onSuccess);
      }

      if (id === ORDER_ACTION.INCIDENT) {
        this.$store.commit("incidents/createResetAllEntities");
        this.$store.commit("incidents/createAddEntities", {
          entities: [
            { type: INCIDENT_ENTITY_TYPE.ODETAILS, items: [{ ...this.data }] },
          ],
          type: INCIDENT_TYPE.INCIDENT,
          show: true,
        });
      }

      if (id === ORDER_ACTION.CANCELLATION) {
        this.$store.commit("incidents/createResetAllEntities");
        this.$store.commit("incidents/createAddEntities", {
          entities: [
            { type: INCIDENT_ENTITY_TYPE.ODETAILS, items: [{ ...this.data }] },
          ],
          type: INCIDENT_TYPE.CANCELLATION,
          show: true,
        });
      }
    },

    canView(perm) {
      return this.$store.getters["connections/havePermission"](perm);
    },
  },
};
</script>
